import ContentfulPage from "../containers/ContentfulPage"
import { getPageProps } from '../utils/getPageProps'

export const WithContentfulPage = (Component) => {
  const WrappedComponent = (props) => {
    return (
      <ContentfulPage pathname={props.pathname} previews={props.previews} >
        <Component {...props} />
      </ContentfulPage>
    )
  }

  WrappedComponent.getInitialProps = async (...args) => {
    const pageProps = await getPageProps(...args)
  
    if (Component.getInitialProps) {
      const pageInitialProps = await Component.getInitialProps(...args)

      return {
        ...pageProps,
        ...pageInitialProps
      }
    }

    return pageProps
  }

  return WrappedComponent
}