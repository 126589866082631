import _ from 'lodash'
import { fetchResources, fetchPreview } from '../store/modules/resources/actions'

export const getPageProps = async ({ query, store, pathname }) => {
  const sanitizedPathname = pathname.replace(/\//g, '')
  const { entryId } = query
  let result = { pathname: sanitizedPathname } 
  if (entryId) {
    try {
      // Fetch preview data if entryId is present
      const previews = await store.dispatch(fetchPreview({
        resourceType: 'page',
        include:2, // includes embedded entries in rich text area
        id: entryId
      }))
      return { ...result, previews } 
    } catch (error) {
      console.error('Error fetching entry:', error)
      return { ...result, errorCode: 404 }
    }
  } else {
    // Fetch normal resources if no entryId is present
    await store.dispatch(fetchResources({
      resourceType: "page",
      requestKey: sanitizedPathname,
      where: {
        "fields.pageUrlName": sanitizedPathname,
      },
    }))
    
    return result
  }
}
