import _ from 'lodash'
import { WithContentfulPage } from '../components/WithContentfulPage'
import { fetchResources } from '../store/modules/resources/actions'
import Router from 'next/router'

const DynamicContentfulPage = ({ errorCode, pathname }) => {

  // Component is wrapped with ContentfulPage in the HOC WithContentfulPage 
  return null

}

DynamicContentfulPage.getInitialProps = async ({ res, store, query }) => {
  const pathname = _.get(query, 'dynamicContentfulPage').replace(/\//g, '')
  if (!pathname) {
    res.statusCode = 500
    return { errorCode: 500 }
  }
  const { entryId, dynamicContentfulPage, ...restQuery } = query
  const redirectQuery = entryId && { entryId, ...restQuery }
  if (_.toLower(pathname) === 'idaps') {
    if (res) {
      res.writeHead(301, { Location: '/idaps' })
      res.end()
    } else {
      Router.replace({
        pathname: '/idaps',
        query: redirectQuery,
      }, undefined, { shallow: true })
    }
  }

  // Fallback if no previews: fetch the published resources from the specified pathname
  const result = await store.dispatch(fetchResources({
    resourceType: 'page',
    requestKey: pathname,
    include:2, // includes embedded entries in rich text area
    where: { 'fields.pageUrlName': pathname }
  }))

  const resources = _.get(result, 'resources')
  
  if (_.isEmpty(resources)) {
    if (res) res.statusCode = 404
    return { errorCode: 404 }
  }

  return { pathname }

}

export default WithContentfulPage(DynamicContentfulPage)
